import { Breakpoints } from './app/utils/breakpoints';

export const FA_LOCATOR_ID = `fa-video-player`;
export const FA_LOCATOR = `[data-test-id=${FA_LOCATOR_ID}]`;

export type Video = {
  alt: string;
  aspectRatio: string;
  poster?: string;
  src: string;
  autoPlay?: boolean;
  loop?: boolean;
  muted?: boolean;
  nativeControls?: boolean;
};

export type Videos = Partial<Breakpoints<Video>>;

export type VideoPlayerConfig = {
  videos: Videos;
  playsInline: boolean;
  cover?: boolean;
  isFormatable?: boolean;
  groupButtonsMobile?: boolean;
};

export interface VideoPlayerProps {
  videos: Videos;
  playsInline: boolean;
  cover?: boolean;
  isFormatable?: boolean;
  groupButtonsMobile?: boolean;
}
