import { useEffect, useState } from 'react';

export const useWindowSize = (): {
  height: number;
  width: number;
} => {
  const [state, setState] = useState({
    height: 0, // Default value for SSR
    width: 0, // Default value for SSR
  });

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const handleResize = (): void => {
        setState({
          height: window.innerHeight,
          width: window.innerWidth,
        });
      };

      handleResize();

      window.addEventListener('resize', handleResize);
      return (): void => {
        window.removeEventListener('resize', handleResize);
      };
    }
    return undefined;
  }, []);

  return state;
};
