/* eslint-disable */
export type BreakpointLabel = 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl';

export type Breakpoints<T> = {
  [label in BreakpointLabel]: T;
};

type BreakpointStylesMappingFunction<T> = (bp: BreakpointLabel) => T;
type BreakpointStyles<T> = {
  [style: string]: Partial<Breakpoints<T> | BreakpointStylesMappingFunction<T>>;
};

export const responsiveStyles = <T extends Record<string, Partial<Breakpoints<T>>>>(
  styles: BreakpointStyles<T>,
  breakpoints: Breakpoints<number>,
) => {
  const styleProp = Object.keys(styles)[0];
  const values = styles[styleProp];

  return (
    Object.entries(values)
      // Sort breakpoints by their min-width values
      .sort(([a], [b]) => breakpoints[a] - breakpoints[b])
      .map(([key, value]) => {
        const minWidth = breakpoints[key] as number;
        return `@media (min-width:${minWidth}px) {${styleProp}:${value};}`;
      })
      .join('')
  );
};
