export const breakpoints = {
  xs: 320,
  s: 375,
  m: 768,
  l: 1024,
  xl: 1440,
  xxl: 1920,
};

export type BreakpointLabel = 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl';

export type Breakpoints<T> = {
  [label in BreakpointLabel]: T;
};
